import TestPage from 'features/misc/routes/TestPage'
import useAuth from 'hooks/useAuth'
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom'
import { FC } from 'react'
import storage from 'utils/storage'
import { useGlobalSettingsQuery, useWebSettingsQuery } from 'api/baseApi'
import { FullLoader } from 'components/elements/Loader'
// import { protectedRoutes } from './protected'
import { useProfileQuery } from 'features/auth'
import { publicRoutes } from './public'
import { handleProtectedRoutes } from './protected'

export const AppRoutes: FC = () => {
 const { isAuthenticated } = useAuth()
 const [searchParams] = useSearchParams()
 const { pathname } = useLocation()
 const token = searchParams.get('token')
 const { data: webSettingsData, isLoading: webSettingsLoading } =
  useWebSettingsQuery(undefined, {
   skip: !token && !isAuthenticated,
  })

 const firstOpen = webSettingsData?.onboarding_settings?.onboardShow?.first_open

 const { isLoading: profileLoading } = useProfileQuery(undefined, {
  skip: !token && !isAuthenticated,
 })
 const { isLoading: globalLoading } = useGlobalSettingsQuery(undefined, {
  skip: !token && !isAuthenticated,
 })

 const routes =
  isAuthenticated || token
   ? handleProtectedRoutes({
      toUonBoard: firstOpen ?? false,
     })
   : publicRoutes

 const element = useRoutes([
  ...routes,
  {
   path: '/test/*',
   element: <TestPage />,
  },
 ])

 if (webSettingsLoading || profileLoading || globalLoading) {
  return <FullLoader />
 }

 if (token) {
  if (pathname === '/admin') {
   storage.setToken(token)
  }
  window.location.href = webSettingsData?.onboarding_settings?.onboardShow
   ?.first_open
   ? '/admin/user-onboard'
   : '/admin/dashboard'
 }

 return element
}
