import { Card, Col } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { globalToaster } from 'components/elements/global'
import { useAppDispatch } from 'hooks'
import { useWebSettings } from 'raducer/webSettings'
import { baseAPI } from 'api/baseApi'
import { TIntegrationFBPages, TIntegrationItem } from '../types'
import { useFbPagesMutation } from '../api'

const { Meta } = Card

const IntegrationCard: FC<{
 itm: TIntegrationItem
 setIntItems: Dispatch<SetStateAction<TIntegrationItem[]>>
 handleUnlinkFbPage: (page_id?: any) => Promise<void>
 key?: number
}> = ({ itm, setIntItems, handleUnlinkFbPage, key }) => {
 const d = useAppDispatch()
 const { onboarding_settings } = useWebSettings()
 const { onBoardListingStatus } = onboarding_settings || {}
 const [fbPages] = useFbPagesMutation()
 const [activeBtnLoading, setActiceBtnLoading] = useState(false)
 const { FB }: any = window

 const handleActiveFbPage = async (val: TIntegrationFBPages) => {
  setActiceBtnLoading(true)
  await FB.api(
   `/${val?.page_id}/subscribed_apps`,
   'post',
   {
    subscribed_fields: 'leadgen',
    access_token: val?.page_access_token,
   },
   (res: any) => {
    if (res?.success) {
     fbPages?.(val)
      ?.unwrap()
      ?.then(() => {
       if (!onBoardListingStatus?.facebook_integration) {
        d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
       }
       setActiceBtnLoading(false)
       setIntItems((prv) =>
        prv.map((itms) =>
         itms?.page_id === val?.page_id ? { ...itms, status: 'Active' } : itms,
        ),
       )
      })
      .catch(() => {
       setActiceBtnLoading(false)
      })
    } else if (res?.error) {
     globalToaster({ type: 'error', content: 'Something went wrong' })
     setActiceBtnLoading(false)
    } else {
     return null
    }
   },
  )
 }
 return (
  <Col xs={24} sm={12} lg={8} className="p-2" key={key}>
   <Card
    title={
     <Meta
      avatar={<i className="fab fa-facebook" />}
      title="Lead Ads Integration"
     />
    }
    extra={
     itm?.status === 'Active' && !itm?.isDelete ? (
      <BaseButton
       onClick={() => handleUnlinkFbPage(`${itm?.page_id}`)}
       buttonStyle="outline"
       buttonType="danger"
       icon={<i className="fas fa-unlink me-2" />}
      >
       Disconnect
      </BaseButton>
     ) : null
    }
    className="item-card"
   >
    <h3 className="page-name">{itm?.page_name} </h3>
    {itm?.status === 'Activate' ? (
     <BaseButton
      buttonStyle="outline"
      buttonType="danger"
      onClick={() => {
       handleActiveFbPage?.({
        page_access_token: itm?.page_access_token,
        page_id: `${itm?.page_id}`,
        page_name: itm?.page_name,
        fb_access_token: FB?.getAccessToken(),
       })
      }}
      loading={activeBtnLoading}
     >
      Activate
     </BaseButton>
    ) : (
     <div className="active-btn">
      {itm?.status}
      <i className="fas fa-check-circle ms-2" />
     </div>
    )}
   </Card>
  </Col>
 )
}

export default IntegrationCard
