import { baseAPI, baseTransformResponse4 } from 'api/baseApi'

export const userOnboardApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  updateOnboard: mutation<
   void,
   { type: 'first_open' | 'popup_status' | 'app_status' }
  >({
   query: (body) => ({
    url: 'user/onboard',
    method: 'post',
    body: { ...body, status: false },
   }),
   transformResponse: baseTransformResponse4,
  }),
 }),
})

export const { useUpdateOnboardMutation } = userOnboardApi
