import { useAppSelector, useAppDispatch } from 'hooks'
import { createSlice } from '@reduxjs/toolkit'
import {
 TGlobalTableStructure,
 TLeadListTableStructure,
 TWebSettings,
} from 'types'
import { baseAPI } from 'api/baseApi'
import { RootState } from 'store'

const USER_ONBOARD_LOCAL = 'user-onboard-local'

const userOnboardState = {
 minimizeOnBoardPopup: false,
 hideQrPopup: 'show',
}

const initialUserOnboardState: TUserOnboardLocal = localStorage.getItem(
 USER_ONBOARD_LOCAL,
)
 ? JSON.parse(`${localStorage.getItem(USER_ONBOARD_LOCAL)}`)
 : userOnboardState

type TUserOnboardLocal = {
 minimizeOnBoardPopup: boolean
 hideQrPopup: string
}
type TUserOnboardGlobalState = {
 showLeadImportPopup: boolean
}

const initialOnboardGlobalState = {
 showLeadImportPopup: false,
}

export type TWebSettingsState = Partial<TWebSettings> & {
 userOnboardLocal: TUserOnboardLocal
 userOnboardGlobalState: TUserOnboardGlobalState
}

const webSettingsState: TWebSettingsState = {
 userOnboardLocal: initialUserOnboardState,
 userOnboardGlobalState: initialOnboardGlobalState,
}

export const webSettingsSlice = createSlice({
 name: 'webSettingsSlice',
 initialState: webSettingsState,
 reducers: {
  updateTableStructure: (state, { payload }) => {
   if (payload?.mode === 'dashboard') {
    state.dashboard_table_structure = payload?.data
   } else {
    state.table_structure = payload?.data
   }
  },
  updateUserOnboardLocal: (state, { payload }) => {
   state.userOnboardLocal = { ...state.userOnboardLocal, ...payload }
   localStorage?.setItem(
    USER_ONBOARD_LOCAL,
    JSON.stringify({ ...state.userOnboardLocal, ...payload }),
   )
  },
  closeUserOnboardPopup: (state) => {
   if (state?.onboarding_settings?.onboardShow) {
    state.onboarding_settings.onboardShow.popup_status = false
   }
  },
  updateUserOnboardGlobalState: (state, { payload }) => {
   state.userOnboardGlobalState = {
    ...state.userOnboardGlobalState,
    ...payload,
   }
  },
  updateQrModalData: (state, { payload }) => {
   if (payload?.type === 'remove-app-data') {
    state.userOnboardLocal = {
     minimizeOnBoardPopup: state?.userOnboardLocal?.minimizeOnBoardPopup,
    } as any
   } else {
    state.userOnboardLocal = { ...state.userOnboardLocal, ...payload?.data }
   }

   if (payload?.type === 'do-not-show') {
    localStorage?.removeItem(USER_ONBOARD_LOCAL)
    state.app_user = false
   } else if (payload?.type === 'close' || payload?.type === 'show') {
    localStorage?.setItem(
     USER_ONBOARD_LOCAL,
     JSON.stringify({ ...state.userOnboardLocal, ...payload?.data }),
    )
    state.app_user = payload?.type !== 'close'
   } else if (payload?.type === 'remove-app-data') {
    localStorage?.setItem(
     USER_ONBOARD_LOCAL,
     JSON.stringify({
      minimizeOnBoardPopup: state?.userOnboardLocal?.minimizeOnBoardPopup,
     }),
    )
    state.app_user = false
   }
  },
 },
 extraReducers(builder) {
  builder.addMatcher(
   baseAPI.endpoints.webSettings.matchFulfilled,
   (state, { payload }) => ({
    ...state,
    ...payload,
   }),
  )
 },
})

export const {
 updateTableStructure,
 updateUserOnboardLocal,
 updateUserOnboardGlobalState,
 //  updateWebSettingsApiData,
 updateQrModalData,
 closeUserOnboardPopup,
} = webSettingsSlice.actions

export const useWebSettings = (): TWebSettingsState & {
 updateTableStructure?: (v: {
  mode?: 'dashboard' | 'leads'
  data: TLeadListTableStructure[] | TGlobalTableStructure[]
 }) => void
 updateUserOnboardLocal: (v: Partial<TUserOnboardLocal>) => void
 updateUserOnboardGlobalState: (v: TUserOnboardGlobalState) => void
 //  updateWebSettingsApiData: (v: Partial<TWebSettingsState>) => void
 updateQrModalData: (v: {
  type: 'do-not-show' | 'close' | 'show' | 'remove-app-data'
  data?: Partial<TUserOnboardLocal>
 }) => void
 closeUserOnboardPopup: () => void
} => {
 const d = useAppDispatch()
 const webSettings = useAppSelector((state: RootState) => state.webSettings)
 return {
  ...webSettings,

  updateTableStructure: (v) => d(updateTableStructure(v)),
  updateUserOnboardLocal: (v) => {
   d(updateUserOnboardLocal(v))
  },
  updateUserOnboardGlobalState: (v) => {
   d(updateUserOnboardGlobalState(v))
  },
  //   updateWebSettingsApiData: (v) => {
  //    d(updateWebSettingsApiData(v))
  //   },
  updateQrModalData: (v) => {
   d(updateQrModalData(v))
  },
  closeUserOnboardPopup: () => d(closeUserOnboardPopup()),
 }
}
