import { Empty } from 'antd'
import { FC, ReactNode } from 'react'

const MTEmptyBlock: FC<{ columCount: number; emptyNode?: ReactNode }> = ({
 columCount,
 emptyNode,
}) => {
 return (
  <tbody className="m-table-tbody">
   {emptyNode ? (
    <td colSpan={columCount}>
     <div className="m-table-custom-empty-block">{emptyNode}</div>
    </td>
   ) : (
    <tr>
     <td colSpan={columCount}>
      <div className="m-table-placeholder">
       <Empty />
      </div>
     </td>
    </tr>
   )}
  </tbody>
 )
}

MTEmptyBlock.defaultProps = {
 emptyNode: undefined,
}

export default MTEmptyBlock
