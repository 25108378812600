import {
 Navigate,
 Outlet,
 RouteObject,
 useLocation,
 useParams,
} from 'react-router-dom'

import { lazyImport } from 'utils/lazyImport'
import AdminLayout from 'layout/AdminLayout'
import { NotFound } from 'features/misc'
import { FullLoader } from 'components/elements/Loader'
import { Suspense } from 'react'
import { UserOnbordRoutes } from 'features/user-onboard'
import { useWebSettings } from 'raducer/webSettings'

const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard')
const { AutomationRoutes } = lazyImport(
 () => import('features/automation'),
 'AutomationRoutes',
)
const { LeadRoutes } = lazyImport(() => import('features/leads'), 'LeadRoutes')

const { PropertyRoutes } = lazyImport(
 () => import('features/property'),
 'PropertyRoutes',
)

const { RealtorsRoutes } = lazyImport(
 () => import('features/realtors'),
 'RealtorsRoutes',
)
const { MyBrokerageRoutes } = lazyImport(
 () => import('features/my-brokerage'),
 'MyBrokerageRoutes',
)

const { AlertRoutes } = lazyImport(
 () => import('features/alert'),
 'AlertRoutes',
)

const { SettingsRoute } = lazyImport(
 () => import('features/settings'),
 'SettingsRoute',
)

const { IntegrationRoute } = lazyImport(
 () => import('features/integration'),
 'IntegrationRoute',
)

const { ReportRoute } = lazyImport(
 () => import('features/reports'),
 'ReportRoute',
)
const { CalendarRoute } = lazyImport(
 () => import('features/calendar'),
 'CalendarRoute',
)

const { ProfileRoutes } = lazyImport(
 () => import('features/profile'),
 'ProfileRoutes',
)
const { InboxRoute } = lazyImport(() => import('features/inbox'), 'InboxRoute')

/// //////////
const App = () => {
 const { pathname } = useLocation()
 //  const { onboarding_settings } = useWebSettings()
 //  console.log({ onboarding_settings })

 if (pathname?.split('/')?.[2] === 'user-onboard') {
  return <Outlet />
 }

 return (
  <AdminLayout>
   <Suspense fallback={<FullLoader />}>
    <Outlet />
   </Suspense>
  </AdminLayout>
 )
}

export const handleProtectedRoutes = (v: {
 toUonBoard: boolean
}): RouteObject[] => {
 const { toUonBoard } = v || {}
 return [
  {
   path: '/admin/',
   element: <App />,
   children: [
    { path: '*', element: <NotFound /> },
    { path: 'dashboard', element: <Dashboard /> },
    {
     path: '',
     element: (
      <Navigate to={toUonBoard ? '/admin/user-onboard' : '/admin/dashboard'} />
     ),
    },
    { path: 'automation/*', element: <AutomationRoutes /> },
    { path: 'leads/*', element: <LeadRoutes /> },
    { path: 'listing/*', element: <PropertyRoutes /> },
    { path: 'alert/*', element: <AlertRoutes /> },
    { path: 'settings/*', element: <SettingsRoute /> },
    { path: 'integration/*', element: <IntegrationRoute /> },
    { path: 'reports/*', element: <ReportRoute /> },
    { path: 'calendar', element: <CalendarRoute /> },
    { path: 'inbox/*', element: <InboxRoute /> },
    { path: 'realtors/*', element: <RealtorsRoutes /> },
    { path: 'my-brokerage/*', element: <MyBrokerageRoutes /> },
    { path: 'my-profile/*', element: <ProfileRoutes /> },
    { path: 'user-onboard/*', element: <UserOnbordRoutes /> },
   ],
  },
  {
   path: '/auth/*',
   element: <Navigate to="/admin" />,
  },
  {
   path: '/',
   element: <Navigate to="/admin" />,
  },
  { path: '*', element: <NotFound /> },
 ]
}
