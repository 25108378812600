import { Card, Col, Row } from 'antd'
import { MDataTable } from 'components/shared/MDataTable'
import { FC, useState } from 'react'
import useFetchPage from 'hooks/useFetchPage'
import { TGetLeads } from 'features/leads'
import AddMoreColumnBtn from 'features/leads/components/AddMoreColumnBtn'
import { useWebSettings } from 'raducer/webSettings'
import ImportLeadModal from 'components/shared/Modals/ImportLeadModal'
import { BtnAnch } from 'components/elements/global'
import RecentLeadColumns from './RecentLeadColumns'
import { TDashboardToLeadDetail } from '../routes'

const RecentLead: FC<{
 setLeadDetailData: (v: TDashboardToLeadDetail | null) => void
}> = ({ setLeadDetailData }) => {
 const { dashboard_table_structure } = useWebSettings()
 const {
  flatData,
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetLeads>('get-dashboard-activity', {
  method: 'post',
 })
 const [showModal, setShowModal] = useState<boolean>(false)

 if ((!flatData || (flatData && flatData.length === 0)) && !isLoading) {
  return (
   <>
    {/* <div
     className="table-empty-content"
     style={{
      marginTop: '20px',
      backgroundColor: 'white',
      maxHeight: '200px',
      justifyContent: 'center',
     }}
    > */}
    <Row
     gutter={15}
     className="table-empty-content"
     style={{
      marginTop: '20px',
      backgroundColor: 'white',
      maxHeight: '200px',
      justifyContent: 'center',
     }}
    >
     <Col
      span={16}
      className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
     >
      <p>
       There is no recent lead activity to display yet. To get started,
       <BtnAnch onClick={() => setShowModal(true)} className="colored-link-1">
        {' '}
        Click here to import leads and begin tracking their activity.
       </BtnAnch>
      </p>
     </Col>
    </Row>
    {/* </div> */}
    {showModal && (
     <ImportLeadModal
      onCancel={() => {
       refetch?.()
       setShowModal(false)
      }}
      visible={showModal}
     />
    )}
   </>
  )
 }

 return (
  <Card
   loading={isLoading}
   className="mt-3"
   title={
    <div className="d-flex">
     <span className="me-2">Recent Lead Activity</span>
     <AddMoreColumnBtn refetch={refetch} mode="dashboard" />
    </div>
   }
   style={{
    overflow: 'auto',
    height: 'calc(100% - 138px)',
    minHeight: '400px',
   }}
  >
   <MDataTable
    totalData={totalCount}
    loading={isLoading}
    isFetching={isRefetching}
    hasMore={hasMore}
    fetchMore={fetchMore}
    data={flatData}
    columns={RecentLeadColumns({
     onClickLead: (index) => {
      setLeadDetailData({
       fetchMore,
       hasMore,
       index,
       refetch,
       flatData,
      })
     },
     table_structure: dashboard_table_structure,
    })}
    isDraggableColumn
    fromTable="dashboard"
   />
  </Card>
 )
}

export default RecentLead
