/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'
import { useWebSettings } from 'raducer/webSettings'
import { useUpdateOnboardMutation } from '../api'
import { UserOnboardSetupItems } from './UserOnboardSetupItems'

const UserOnboardModal: FC = () => {
 const { updateUserOnboardLocal, userOnboardLocal, closeUserOnboardPopup } =
  useWebSettings()
 const { minimizeOnBoardPopup } = userOnboardLocal || {}
 const [updateOnboard] = useUpdateOnboardMutation()
 const onCloseUserOnboard = async (): Promise<void> => {
  closeUserOnboardPopup?.()
  await updateOnboard({ type: 'popup_status' })
 }

 if (minimizeOnBoardPopup) {
  return (
   <div className="user-onboard-minmized">
    <div
     className="setup-title"
     onClick={() => updateUserOnboardLocal({ minimizeOnBoardPopup: false })}
    >
     Set Up Your CRM
    </div>
    <div className="icons">
     <i
      className="fas fa-compress-alt "
      onClick={() => {
       updateUserOnboardLocal({ minimizeOnBoardPopup: false })
      }}
     />
     <i className="fas fa-times" onClick={onCloseUserOnboard} />
    </div>
   </div>
  )
 }

 return (
  <div className="user-onboard-modal">
   <div className="content">
    <div className="header">
     <div className="actions">
      <i
       className="fal fa-minus"
       onClick={() => updateUserOnboardLocal({ minimizeOnBoardPopup: true })}
      />

      <i className="fas fa-times" onClick={onCloseUserOnboard} />
     </div>
     <div className="header-content">
      <div>
       <i className="fas fa-cog icon" />
      </div>
      <div>
       <h1 className="title">SET UP YOUR CRM</h1>
       <p className="subtitle">Make your CRM smarter and more interactive</p>
      </div>
     </div>
    </div>
    <div>
     <UserOnboardSetupItems mode="modal" />
    </div>
   </div>
  </div>
 )
}

export default UserOnboardModal
