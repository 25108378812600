import {
 baseAPI,
 baseTransformResponse,
 baseTransformResponse2,
 baseTransformResponse3,
} from 'api/baseApi'
import { TAddBackGroundType } from 'components/shared/Modals/BackgroundModal'
import { TAddCallLog } from 'components/shared/Modals/LogCallModal'
import { queryClient } from 'lib/react-query'
import { ApiResponce, TGroupName } from 'types'
import { globalToaster } from 'components/elements/global'
import { TAlertFeedType } from 'features/alert'
import { TAddCity } from '../routes/details/profile/Boxes/CityBlock'
import {
 AddAddresses,
 AddEmailType,
 AddPhoneType,
 TAddLeadEventBody,
 TGetAnalyticsOrder,
 TGetLead,
 TGetLeadFilter,
 TLeadAnalytics,
 //  TLeadActivity,
 TLeadTile,
 TPreviewTemplateBody,
 TRecentActivityDetail,
 TSelectAllB,
 TSendScheduleSmsBody,
 TSendTextBody,
 TStoreLead,
} from '../types'
import { TRecentAcitivityReq } from '../routes/details/activity/AllActivity'

export const refetchLeadList = () => queryClient.invalidateQueries('get-leads')

export const leadApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  getLoadFilters: query<TGetLeadFilter, any>({
   query: () => 'user/get-lead-filter',
   transformResponse: (res: ApiResponce) => res.data,
  }),
  addLead: mutation<void, TStoreLead>({
   query: (body) => ({
    url: 'user/put-lead',
    method: 'post',
    body,
   }),
   async onQueryStarted(_, { queryFulfilled }) {
    await queryFulfilled
    refetchLeadList()
   },
   transformResponse: baseTransformResponse,
  }),
  deleteLeads: mutation<void, { id: string[] }>({
   query: (body) => ({
    url: 'user/delete-leads',
    method: 'post',
    body,
   }),
   async onQueryStarted(_, { queryFulfilled }) {
    await queryFulfilled
    refetchLeadList()
   },
   transformResponse: baseTransformResponse,
  }),
  leadExport: mutation<any, { lead_id?: any[]; crm_list_type: string }>({
   query: (body) => ({
    url: 'user/export-lead',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  sendEmailLead: mutation<void, any>({
   query: (body) => ({
    url: 'user/sendmail',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendScheduleEmailLead: mutation<void, any>({
   query: (body) => ({
    url: 'user/profile-schedule-email',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendScheduleEmailPro: mutation<void, any>({
   query: (body) => ({
    url: 'user/mls-schedule-email',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendScheduleSmsPro: mutation<void, any>({
   query: (body) => ({
    url: 'user/mls-schedule-text',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendSmsLead: mutation<void, TSendTextBody>({
   query: (body) => ({
    url: 'user/sendText',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendScheduleSmsLead: mutation<void, TSendScheduleSmsBody>({
   query: (body) => ({
    url: 'user/profile-schedule-text',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  previewEmailLead: query<TPreviewTemplateBody, TPreviewTemplateBody>({
   query: (body) => ({
    url: 'user/mail-preview',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),
  previewSmsLead: query<TPreviewTemplateBody, TPreviewTemplateBody>({
   query: (body) => ({
    url: 'user/text-preview',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  assignAlertToLead: mutation<
   void,
   TSelectAllB & {
    lead_id?: any[]
    alert_id: any[]
    type?: 'lead' | 'alert'
    feed?: TAlertFeedType
   }
  >({
   query: (body) => ({
    url: 'user/assign-alert-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  assignGroupToLeadBulk: mutation<
   void,
   { type?: TGroupName; lead_id?: any[]; group_id?: any[] }
  >({
   query: (body) => ({
    url: 'user/assign-lead-bulk',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  getLead: query<TGetLead, { id?: any; realtor_id?: any }>({
   query: ({ id, realtor_id }) =>
    `user/get-lead/${id}${realtor_id ? `?realtor_id=${realtor_id}` : ''}`,
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['LEAD'],
  }),
  getLeadProfile: query<TGetLead, { id?: any; realtor_id?: any }>({
   query: ({ id, realtor_id }) =>
    `user/get-lead/${id}${realtor_id ? `?realtor_id=${realtor_id}` : ''}`,
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['LEAD'],
  }),
  addLeadEmail: mutation<void, AddEmailType>({
   query: (body) => ({
    url: 'user/put-email-address',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),

  deleteEmailAddress: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-email-addresses',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  AddAddresses: mutation<void, AddAddresses>({
   query: (body) => ({
    url: 'user/put-address',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  addLeadPhone: mutation<void, AddPhoneType>({
   query: (body) => ({
    url: 'user/put-phone-number',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  deletePhoneAddress: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-phone-numbers',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  //   getLeadActivities: query<TLeadActivity[], { id?: any }>({
  //    query: ({ id }) => ({
  //     url: `user/lead/profile/activity/react/${id}`,
  //     method: 'post',
  //    }),
  //    transformResponse: (res: ApiResponce) => res.data,
  //   }),
  addLeadEvent: mutation<{ id?: any }, TAddLeadEventBody>({
   query: (body) => ({
    url: 'user/put-event',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse2,
   invalidatesTags: ['LEAD'],
  }),
  updateLeadEvent: mutation<void, TAddLeadEventBody>({
   query: ({ id, ...body }) => ({
    url: `user/update-event/${id}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  deleteLeadEvent: mutation<void, { id?: any; feed?: string }>({
   query: (body) => ({
    url: 'user/delete-event',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  taskCompleted: mutation<void, { id: string[] }>({
   query: (body) => ({
    url: 'user/event-tasks-complete',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  deleteUserAddresses: mutation<void, { id: string }>({
   query: (body) => ({
    url: 'user/delete-addresses',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  updateLeadData: mutation<void, { id?: string; type: any; value?: any }>({
   query: (body) => ({
    url: 'user/put-lead-data',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  uploadLeadFile: mutation<void, any>({
   query: (body) => ({
    url: 'user/put-file',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  addNoteMutation: mutation<void, { content?: string; lead_id?: any }>({
   query: (body) => ({
    url: 'user/put-note',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  deleteLeadFile: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-files',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  leadTransfer: mutation<void, TSelectAllB & { id?: string; realtor?: any }>({
   query: (body) => ({
    url: 'user/transfer-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  withdrawAlertLead: mutation<void, { lead_id?: string; alert_id: any }>({
   query: (body) => ({
    url: 'user/withdraw-alert-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  withdrawTaglead: mutation<void, { lead_id?: any; tag_id?: string }>({
   query: (body) => ({
    url: 'user/withdraw-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  assignAlertLead: mutation<
   void,
   { lead_id?: any[]; alert_id: any[]; type?: 'lead' }
  >({
   query: (body) => ({
    url: 'user/assign-alert-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  leadImport: mutation<ApiResponce, { formData?: any; mode?: 'fb' | 'dir' }>({
   query: ({ formData, mode }) => ({
    url: `user/${mode === 'dir' ? 'import-lead' : 'import-fb-lead'}`,
    method: 'post',
    body: formData,
   }),
   //    transformResponse: (res),
  }),
  assignActionPlanLead: mutation<
   void,
   TSelectAllB & {
    lead_id?: any[]
    actionplan_id?: any[]
    type?: 'action-plan'
   }
  >({
   query: (body) => ({
    url: 'user/assign-action-plan-lead',
    method: 'post',
    body,
   }),
   // eslint-disable-next-line no-empty-pattern
   //    async onQueryStarted({}, { queryFulfilled }) {
   //     await queryFulfilled
   //     queryClient.invalidateQueries('get-leads')
   //    },
   transformResponse: baseTransformResponse,
  }),
  getLeadTiles: query<TLeadTile, { type?: 'myleads' }>({
   query: (body) => ({
    method: 'post',
    url: 'user/get-lead-tile',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
   providesTags: ['LEAD-TILE'],
  }),
  addCallLog: mutation<void, TAddCallLog>({
   query: (body) => ({
    url: 'user/put-calllogs',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  addBackGround: mutation<void, TAddBackGroundType>({
   query: (body) => ({
    url: 'user/update-background',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  addCity: mutation<void, TAddCity>({
   query: (body) => ({
    url: 'user/update-city',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  deleteCity: mutation<void, { city_id?: any }>({
   query: (body) => ({
    url: 'user/delete-city',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  //   addPriceRange: mutation<void, TAddPriceRange>({
  //    query: (body) => ({
  //     url: 'user/update-price-range',
  //     method: 'post',
  //     body,
  //    }),
  //    invalidatesTags: ['LEAD'],
  //    transformResponse: baseTransformResponse,
  //   }),
  updateLeadlogdataStatus: mutation<void, { id?: number; type?: 'email' }>({
   query: (body) => ({
    url: 'user/update-leadlogdata-status',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),
  recentActivityDetail: query<TRecentActivityDetail[], TRecentAcitivityReq>({
   query: (body) => ({
    url: 'user/recent-activity-detail',
    method: 'post',
    body,
   }),
   transformResponse: (resp: ApiResponce<TRecentActivityDetail[]>): any => {
    if (resp.status === 'error') {
     globalToaster({ type: 'error', content: resp.message })
     return false
    }
    return resp.data?.[0]
   },
  }),

  getLeadAnalytics: query<TLeadAnalytics, { leadId?: number; type: string }>({
   query: ({ leadId, type }) => ({
    method: 'post',
    url: `user/get-lead-analytics/${leadId}`,
    body: { type },
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getAnalyticsOrder: query<TGetAnalyticsOrder[], { leadId?: number }>({
   query: ({ leadId }) => ({
    method: 'post',
    url: `user/get-analytics-order/${leadId}`,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  resendOtp: query<void, { showToast: boolean }>({
   query: (body) => ({
    url: 'user/sent-otp',
    method: 'get',
    body,
   }),
   transformResponse: (resp: any, _, { showToast }) => {
    if (showToast) {
     baseTransformResponse2(resp)
    } else {
     baseTransformResponse3(resp)
    }
   },
  }),
  verifyOtp: mutation<void, { otp: string }>({
   query: (body) => ({
    url: 'user/verify-otp',
    method: 'post',
    body,
   }),
  }),
  blockAndUnblockEmail: mutation<
   void,
   { lead_id: any; email: any; report: 'Block' | 'Unblock' }
  >({
   query: (body) => ({
    url: 'user/block-emails',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  blockAndUnblockSms: mutation<
   void,
   { lead_id: any; number: any; report: 'Block' | 'Unblock' }
  >({
   query: (body) => ({
    url: 'user/block-sms',
    method: 'post',
    body,
   }),
   invalidatesTags: ['LEAD'],
   transformResponse: baseTransformResponse,
  }),
  updateTableStructure: mutation<
   void,
   { table_structure?: string; dashboard_structure?: string }
  >({
   query: (body) => ({
    url: 'user/update-table-structure',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),
  updateName: mutation<void, { lead_id?: number; name?: string }>({
   query: (body) => ({
    url: 'user/put-lead-name',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse3,
  }),

  /// END
 }),
})

export const {
 useSendScheduleEmailProMutation,
 useAddCityMutation,
 useAddCallLogMutation,
 useAddBackGroundMutation,
 //  useAddPriceRangeMutation,
 useSendScheduleSmsProMutation,
 useGetLeadTilesQuery,
 useLeadImportMutation,
 useAddAddressesMutation,
 useAddLeadEmailMutation,
 useAddNoteMutationMutation,
 useDeleteLeadFileMutation,
 useWithdrawTagleadMutation,
 useUploadLeadFileMutation,
 useUpdateLeadDataMutation,
 useDeleteUserAddressesMutation,
 useDeleteLeadEventMutation,
 useLeadTransferMutation,
 useTaskCompletedMutation,
 useAddLeadEventMutation,
 useUpdateLeadEventMutation,
 useAssignAlertLeadMutation,
 //  useGetLeadActivitiesQuery,
 useAddLeadPhoneMutation,
 useDeleteEmailAddressMutation,
 useDeletePhoneAddressMutation,
 useGetLeadQuery,
 useGetLeadProfileQuery,
 useAssignGroupToLeadBulkMutation,
 useAssignAlertToLeadMutation,
 useLeadExportMutation,
 useGetLoadFiltersQuery,
 useDeleteLeadsMutation,
 useAddLeadMutation,
 useSendEmailLeadMutation,
 useSendSmsLeadMutation,
 useSendScheduleEmailLeadMutation,
 useSendScheduleSmsLeadMutation,
 usePreviewSmsLeadQuery,
 usePreviewEmailLeadQuery,
 useAssignActionPlanLeadMutation,
 useWithdrawAlertLeadMutation,
 useUpdateLeadlogdataStatusMutation,
 useLazyRecentActivityDetailQuery,
 useLazyResendOtpQuery,
 //  useVerifyOtpQuery,
 useVerifyOtpMutation,
 useDeleteCityMutation,
 useBlockAndUnblockEmailMutation,
 useBlockAndUnblockSmsMutation,
 useUpdateTableStructureMutation,
 useUpdateNameMutation,
 useGetLeadAnalyticsQuery,
 useGetAnalyticsOrderQuery,
} = leadApi
