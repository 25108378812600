/* eslint-disable react/jsx-no-useless-fragment */
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useState } from 'react'
import useFetchPageNew from 'hooks/useFetchpageNew'
import { globalConfirm } from 'components/elements/global'
import useSelectAll from 'hooks/useSelectAll'
import { MDataTable } from 'components/shared/MDataTable'
import { Col, Row } from 'antd'
import { TRealtors } from '../types'
import TableColumns from '../components/tableColumns'
import {
 useCancelRealtorRequestMutation,
 useRemoveRealtorMutation,
} from '../api'
import ActionBlockRealtor from '../components/ActionBlockRealtor'
import TransferredLeadList from './TransferredLeadList'

const RealtorsList: FC = () => {
 const [searchKey, setSearchKey] = useState('')
 const [realtorData, setRealtorData] = useState<{
  id: number | null
  name?: string
 }>({ id: null })
 const {
  isLoading,
  hasMore,
  fetchMore,
  flatData,
  refetch,
  total,
  isRefetching,
 } = useFetchPageNew<TRealtors>('brokerage-user-management', {
  method: 'post',
  body: { search: searchKey },
 })
 const {
  selectAll,
  selectedKeys,
  rowSelectionState,
  unSelected,
  onSelectRow,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }), flatData)

 const [rmRealtor] = useRemoveRealtorMutation()
 const [cancelRealtorReq] = useCancelRealtorRequestMutation()

 const onDltRealtor = async (id: number) => {
  globalConfirm({
   cancelText: 'No',
   okText: 'Yes',
   content: 'Are you sure want to delete this realtor?',

   onOk: async () => {
    await rmRealtor({ id }).unwrap()
    refetch?.()
   },
  })
 }

 const onCancelReq = async (id: number) => {
  globalConfirm({
   cancelText: 'No',
   okText: 'Yes',
   content: 'Are you sure want to cancel this request?',

   onOk: async () => {
    await cancelRealtorReq({ id }).unwrap()
    refetch?.()
   },
  })
 }

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 const emptyContent = (
  <Row gutter={15} className="table-empty-content">
   <Col
    span={16}
    className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
   >
    <p>
     No Realtors Connected with your brokerage CRM. To get started, send a
     connection invitation to the realtors working with you and instruct them to
     accept the same from their CRM.
    </p>
   </Col>
  </Row>
 )

 return (
  <>
   {realtorData?.id !== null && flatData && flatData?.length > 0 ? (
    <TransferredLeadList
     realtorData={realtorData}
     onClose={() => setRealtorData({ id: null, name: '' })}
     refetchRealtors={refetch}
    />
   ) : (
    <ListContentLayout
     title="My Realtors"
     actions={
      <ActionBlockRealtor
       handleDelete={() => onDltRealtor(selectedKeys?.[0])}
       refetchRealtor={refetch}
       selectedKeys={selectedKeys}
       selectAll={selectAll}
       unSelected={unSelected}
       filterInfo={JSON.stringify({ search: searchKey })}
      />
     }
    >
     {!isLoading &&
     (searchKey?.length ?? 0) === 0 &&
     (!flatData || (flatData && flatData.length === 0)) ? (
      emptyContent
     ) : (
      <MDataTable
       totalData={total || 1000}
       search={{
        placeholder: 'Search for Realtors',
        onSearch: setSearchKey,
        show: true,
       }}
       onSelectRow={onSelectRow}
       rowSelectionState={{
        ...rowSelectionState,
       }}
       loading={isLoading}
       isFetching={isRefetching}
       hasMore={hasMore}
       fetchMore={() => {
        fetchMore()
       }}
       data={flatData}
       columns={TableColumns({
        onClickRealtor: (id, name) => setRealtorData({ id, name }),
        onDelete: onDltRealtor,
        refetch,
        selectedKeys,
        selectAll,
        unSelected,
        onCancelReq,
       })}
      />
     )}
    </ListContentLayout>
   )}
  </>
 )
}

export default RealtorsList
