import React, { useState, useEffect, FC, useRef } from 'react'
import { motion } from 'framer-motion'
// import { useInterval } from 'react-use' // For managing the interval

export const CountUp: FC<{ end: number; duration?: number }> = ({
 duration = 0.5,
 end,
}) => {
 const [count, setCount] = useState(0)
 const startTimeRef = useRef<null | number>(null)
 const frameRef = useRef<null | number>(null)

 useEffect(() => {
  startTimeRef.current = performance.now()

  const animate = (currentTime: any) => {
   if (!startTimeRef.current) return

   const elapsedTime = (currentTime - startTimeRef.current) / 1000 // Convert to seconds
   const progress = Math.min(elapsedTime / duration, 1)

   // Using easeOutQuad easing function for smoother animation
   const easeProgress = 1 - (1 - progress) * (1 - progress)

   const currentCount = Math.round(easeProgress * end)
   setCount(currentCount)

   if (progress < 1) {
    frameRef.current = requestAnimationFrame(animate)
   }
  }

  frameRef.current = requestAnimationFrame(animate)

  return () => {
   if (frameRef.current) {
    cancelAnimationFrame(frameRef.current)
   }
  }
 }, [end, duration])

 return (
  <motion.div
   initial={{ opacity: 0 }}
   animate={{ opacity: 1 }}
   transition={{ duration: 0.3 }}
  >
   {count}
  </motion.div>
 )
}
