/* eslint-disable jsx-a11y/media-has-caption */
import { Modal } from 'antd'
import React, { FC } from 'react'

const UserOnBoardVideoModal: FC<{ onCancel: () => void }> = ({ onCancel }) => {
 return (
  <Modal
   footer={null}
   closeIcon=""
   className="on-board-vid-modal"
   visible
   onCancel={onCancel}
  >
   <video width="100%" height="360" controls>
    <source
     src="https://cdn.agentroof.com/crm-video/onboard.mp4"
     type="video/mp4"
    />
    Your browser does not support the video tag.
   </video>
  </Modal>
 )
}

export default UserOnBoardVideoModal
