import { useWebSettings } from 'raducer/webSettings'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { baseAPI, useManageWebsiteMutation } from 'api/baseApi'
import { ADMIN_PREFIX_PATH } from 'config'
import { useAppDispatch } from 'hooks'
import { useUpdateOnboardMutation } from '../api'

export const UserOnboardSetupItems: FC<{
 selectedValue?: string
 onHover?: React.Dispatch<
  React.SetStateAction<{
   heading: string
   desc: string
   button: string
   icon: JSX.Element
   isCompleted: boolean
   onClickparam: {
    to: string
   }
  }>
 >
 mode: 'page' | 'modal'
}> = ({ mode, onHover, selectedValue }) => {
 const navigate = useNavigate()
 const { pathname } = useLocation()
 const {
  onboarding_settings,
  updateUserOnboardGlobalState,
  updateUserOnboardLocal,
  updateQrModalData,
 } = useWebSettings()
 const { onBoardListingStatus } = onboarding_settings || {}
 const [updateOnboard] = useUpdateOnboardMutation()
 const [manageWebsiteMutation] = useManageWebsiteMutation()
 const d = useAppDispatch()

 const onCloseUserOnboard = async (): Promise<void> => {
  await updateOnboard({ type: 'first_open' })
 }
 const handleClick = (v: {
  to?: string
  action?: 'manage-website' | 'download-app'
 }): void => {
  const { to, action } = v || {}
  onCloseUserOnboard()

  if (mode === 'modal') {
   updateUserOnboardLocal({ minimizeOnBoardPopup: true })
  }

  if (!action) {
   if (!(mode === 'modal' && pathname === `${ADMIN_PREFIX_PATH}/${to}`)) {
    navigate(`${ADMIN_PREFIX_PATH}/${to}`)
   }
   if (to === 'leads/all-leads') {
    updateUserOnboardGlobalState({ showLeadImportPopup: true })
   }
  } else {
   navigate(`${ADMIN_PREFIX_PATH}/dashboard`)
   if (action === 'manage-website') {
    manageWebsiteMutation?.().then((res: any) => {
     if (!onBoardListingStatus?.manage_website) {
      d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
     }
     if (res?.data?.url) {
      window.open(res.data.url, '_blank')
     }
    })
   } else if (action === 'download-app') {
    updateQrModalData({
     type: 'show',
     data: { hideQrPopup: 'show-over-onboard' },
    })
   }
  }
 }

 const items = [
  {
   label: 'Update your profile',
   icon: <i className="fas fa-user-plus" />,
   onClickParam: { to: 'my-profile' },
   isCompleted: onBoardListingStatus?.profile_update,
   desc: 'Complete your profile to personalize your CRM experience',
   button: 'Update Profile',
  },
  {
   label: 'Configure your listing settings',
   icon: <i className="fas fa-layer-group" />,
   onClickParam: { to: 'settings/listing-settings' },
   isCompleted: onBoardListingStatus?.listing_settings,
   desc:
    'Configure your MLS listing settings to reflect your area and brokerage.',
   button: 'Configure Listings',
  },
  {
   label: 'Add your email signature',
   icon: <i className="fas fa-envelope" />,
   onClickParam: { to: 'settings/signature' },
   isCompleted: onBoardListingStatus?.signature,
   desc: 'Set up your email signature to enhance communication with your leads',
   button: 'Add Signature',
  },
  {
   label: 'Migrate your existing data',
   icon: <i className="fas fa-database" />,
   onClickParam: { to: 'leads/all-leads' },
   isCompleted: onBoardListingStatus?.add_lead,
   desc: 'Import your data to get started with your CRM seamlessly',
   button: 'Import Data',
  },
  {
   label: 'Connect your Facebook page',
   icon: <i className="fab fa-facebook" />,
   onClickParam: { to: 'integration' },
   isCompleted: onBoardListingStatus?.facebook_integration,
   desc: 'Link your Facebook page to expand your reach and manage social leads',
   button: 'Connect Facebook',
  },
  {
   label: 'Manage Your Website',
   icon: <i className="fas fa-solid fa-globe" />,
   onClickParam: { action: 'manage-website' } as any,
   isCompleted: onBoardListingStatus?.manage_website,
   desc:
    'Take control of your website settings to optimize your online presence',
   button: 'Manage Website',
  },
  {
   label: 'Download CRM App',
   icon: <i className="fas fa-download" />,
   onClickParam: { action: 'download-app' } as any,
   isCompleted: onBoardListingStatus?.app_user,
   desc:
    'Take your leads on the go! Download our CRM app now to manage your leads anytime, anywhere.',
   button: 'Download App',
  },
 ]

 return (
  <div
   className={`user-onboard-setup-items ${mode === 'page' ? 'is-page' : ''}`}
  >
   {items?.map((item, n) => (
    <div
     key={n}
     className={`item ${item?.isCompleted ? 'completed-item' : ''} ${
      selectedValue === item?.label ? 'active' : ''
     }`}
     {...(onHover
      ? {
         onMouseEnter: () =>
          onHover({
           button: item?.button,
           desc: item?.desc,
           heading: item?.label,
           icon: item?.icon,
           isCompleted: item?.isCompleted ?? false,
           onClickparam: item?.onClickParam,
          }),
        }
      : {
         onClick:
          //  item?.isCompleted
          //   ? undefined
          //   :
          () => handleClick(item?.onClickParam),
        })}
    >
     {item?.icon}
     {item?.label}
     {item?.isCompleted && <i className="fas fa-check-circle" />}
    </div>
   ))}
  </div>
 )
}
