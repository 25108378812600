/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Carousel, Col, Row } from 'antd'
import { useGetBannersQuery, useGetDashBoardDataQuery } from 'api/baseApi'
import {
 SVGActionPlanDashboard,
 SVGInboxDashboard,
 SVGLeadsDashboard,
 SVGTaskDashboard,
} from 'components/icons'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useMemo, useState } from 'react'
import LeadOverlaySection from 'features/leads/components/LeadOverlaySection'
import { TGetLeads } from 'features/leads'
import { useMeasure, useMedia } from 'react-use'
import { useNavigate } from 'react-router'
import { ADMIN_PREFIX_PATH } from 'config'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import DashboardCard from '../components/DashboardCard'
import RecentLead from '../components/RecentLead'

export type TDashboardToLeadDetail = {
 index: number
 fetchMore: () => void
 hasMore?: boolean
 flatData?: TGetLeads[]
 refetch: () => void
}

export const Dashboard: FC = () => {
 const smScreen = useMedia('(max-width: 1400px)')
 const extsmScreen = useMedia('(max-width: 1150px)')
 const navigate = useNavigate()
 const { data, isLoading } = useGetDashBoardDataQuery()
 const { data: banners, isLoading: bannersLoading } = useGetBannersQuery()
 const { updateLeadListTile, updateRecentLeadData, recentLeadData } =
  useLeadFilter()
 const [ref, { height }] = useMeasure<HTMLDivElement>()

 const cardSize = useMemo(() => {
  if (isLoading || bannersLoading) {
   return smScreen ? 12 : 24
  }
  return banners?.length ? (smScreen ? 12 : 24) : 6
 }, [isLoading, banners, bannersLoading, smScreen])

 const [leadDetailFunctions, setLeadDetailFunctions] = useState<{
  fetchMore?: () => void
  refetch?: () => void
 }>()

 useEffect(() => {
  if (!recentLeadData) {
   setLeadDetailFunctions(undefined)
  }
 }, [recentLeadData])

 return (
  <>
   {recentLeadData && (
    <LeadOverlaySection
     leadIndex={recentLeadData?.index}
     setLeadIndex={(v: any) =>
      updateRecentLeadData({ ...recentLeadData, index: v })
     }
     fetchMore={() => leadDetailFunctions?.fetchMore?.()}
     hasMore={recentLeadData?.hasMore}
     leads={recentLeadData?.flatData}
     isRealtor={false}
     refetchList={() => leadDetailFunctions?.refetch?.()}
    />
   )}
   <ListContentLayout bodyClass="dashboard-page" title="Dashboard">
    <div ref={ref}>
     <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
      <Col
       span={
        extsmScreen || !(isLoading || bannersLoading || banners?.length)
         ? 24
         : smScreen
         ? 10
         : 4
       }
      >
       <Row gutter={[10, 10]} className="dashboardcard-icons">
        <Col span={cardSize}>
         <DashboardCard
          loading={isLoading}
          title="Leads"
          icon={<SVGLeadsDashboard />}
          extra={[
           {
            label: 'Today',
            value: data?.todaylead,
            onClick: () => navigate(`${ADMIN_PREFIX_PATH}/leads/all-leads`),
           },
           {
            label: 'Active',
            value: data?.activeLeads,
            onClick: () => {
             updateLeadListTile('activeLead')
             navigate(`${ADMIN_PREFIX_PATH}/leads/all-leads`)
            },
           },
          ]}
         />
        </Col>
        <Col span={cardSize}>
         <DashboardCard
          loading={isLoading}
          title="Action Plan"
          icon={<SVGActionPlanDashboard />}
          extra={[
           {
            label: 'Active',
            value: data?.activeplans,
            onClick: () =>
             navigate(`${ADMIN_PREFIX_PATH}/automation/action-plan`),
           },
          ]}
         />
        </Col>
        <Col span={cardSize}>
         <DashboardCard
          loading={isLoading}
          title="Inbox"
          icon={<SVGInboxDashboard />}
          extra={[
           {
            label: 'E-mail',
            value: data?.inbox.totemail,
            onClick: () => navigate(`${ADMIN_PREFIX_PATH}/inbox/email/inbox`),
           },
           {
            label: 'SMS',
            value: data?.inbox.totsms,
            onClick: () => navigate(`${ADMIN_PREFIX_PATH}/inbox/sms/inbox`),
           },
          ]}
         />
        </Col>
        <Col span={cardSize}>
         <DashboardCard
          loading={isLoading}
          title="Todays Events"
          icon={<SVGTaskDashboard />}
          extra={[
           {
            label: 'Tasks',
            value: data?.events.task,

            onClick: () => navigate(`${ADMIN_PREFIX_PATH}/calendar`),
           },
           {
            label: 'Appointments',
            value: data?.events.app,
            onClick: () => navigate(`${ADMIN_PREFIX_PATH}/calendar`),
           },
          ]}
         />
        </Col>
       </Row>
      </Col>
      {banners?.length ? (
       <Col
        span={extsmScreen ? 24 : smScreen ? 14 : 20}
        className="dashboard-banners"
       >
        <Carousel
         autoplay
         effect="fade"
         arrows
         prevArrow={<i className="fas fa-chevron-left" />}
         nextArrow={<i className="fas fa-chevron-right" />}
         className="dashboard-carousel"
         infinite
        >
         {banners?.map?.((i, n) => (
          <div key={n}>
           <img
            className="w-100"
            style={{
             background: '#d4d4d4',
             aspectRatio: '8 / 3',
             width: '100%',
             ...(i?.redirect_url
              ? {
                 cursor: 'pointer',
                }
              : {}),
            }}
            alt=""
            src={i?.image}
            onClick={
             i?.redirect_url
              ? () => {
                 try {
                  const url = new URL(i?.redirect_url)
                  window.open(url, '_blank')
                 } catch {
                  window.open(`https://${i?.redirect_url}`, '_blank')
                 }
                }
              : undefined
            }
           />
          </div>
         ))}
        </Carousel>
       </Col>
      ) : (
       ''
      )}
     </Row>
    </div>
    <RecentLead
     setLeadDetailData={(v) => {
      updateRecentLeadData?.({
       flatData: v?.flatData,
       hasMore: v?.hasMore,
       index: v?.index as number,
      })
      setLeadDetailFunctions?.({ fetchMore: v?.fetchMore, refetch: v?.refetch })
     }}
    />
   </ListContentLayout>
  </>
 )
}
