/* eslint-disable react/no-unescaped-entities */
import { Col, Form, Modal, Row } from 'antd'
import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { TModalState } from 'types'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import useFetchPage2 from 'hooks/useFetchPage2'
import { BtnAnch } from 'components/elements/global'
import { TAlertFeedType, TGetAlerts, TPutAlertType } from '../types'
import AlertBulkActionBlock from './AlertBulkActionBlock'
import AlertFormSection from './AlertFormSection'
import AlertTableColumns from './AlerttableColumns'

const AlertList: FC<{ type?: TAlertFeedType }> = ({ type = 'forsale' }) => {
 const [modalState, setModal] = useState<TModalState<TPutAlertType>>({
  show: false,
  mode: 'ADD',
  data: {
   fead: 'forsale',
  },
 })
 const [searchKey, setSearchKey] = useState('')
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  isRefetching,
  meta,
 } = useFetchPage2<TGetAlerts>('get-alerts', {
  method: 'post',
  body: useMemo(() => ({ fead: [type], search: searchKey }), [type, searchKey]),
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectAll,
  unSelected,
  setSelectAll,
  setUnSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 useEffect(() => {
  setSelectAll(false)
  setUnSelected([])
  setSearchKey('')
  setSelectedRow([])
 }, [type])

 const [form] = Form.useForm<
  TPutAlertType & { priceRange?: { min?: any; max?: any } }
 >()

 const alertTitle = useMemo(() => {
  switch (type) {
   case 'forsale':
    return 'New Listing Alert'
   case 'sold':
    return 'Neighbourhood Alert'
   case 'openhouse':
    return 'Open House Alert'
   case 'price-drop':
    return 'Price Drop Alert'
   default:
    return ''
  }
 }, [type])

 const AlertEmptyMessage: ReactNode = useMemo(() => {
  switch (type) {
   case 'forsale':
    return (
     //  <div className="table-empty-content">
     <p>
      The "New Listing Alert" feature allows you to set up listing alerts based
      on City, Property type, Price range, Bed, Bath etc. You can choose the
      alert frequency and assign them to Tags, Stages, or Individual leads. Once
      set, email alerts will be sent to your leads whenever a matching property
      is listed, keeping them informed about the latest listings.{' '}
      <BtnAnch
       className="colored-link-1"
       onClick={() => setModal({ show: true, mode: 'ADD' })}
      >
       Click here to set up a ‘New Listing Alert’
      </BtnAnch>
     </p>
     //  </div>
    )
   case 'sold':
    return (
     //  <div className="table-empty-content">
     <p>
      The "Neighbourhood Alert" feature allows you to set up sold listing alerts
      based on City, Property type, Price range, Bed, Bath etc. You can choose
      the alert frequency and assign them to Tags, Stages, or Individual leads.
      Once set, email alerts will be sent to your leads whenever a matching
      property is sold, keeping them informed about the trends in their
      neighbourhood.{' '}
      <BtnAnch
       className="colored-link-1"
       onClick={() => setModal({ show: true, mode: 'ADD' })}
      >
       Click here to set up a ‘Neighbourhood Alert’
      </BtnAnch>
     </p>
     //  </div>
    )
   case 'openhouse':
    return (
     //  <div className="table-empty-content">
     <p>
      The "Open House Alert" feature allows you to set up listing alerts based
      on City, Property type, Price range, “Open On” date, Bed, Bath etc. You
      can choose the alert frequency and assign them to Tags, Stages, or
      Individual leads. Once set, email alerts will be sent to your leads
      whenever a matching property is listed, keeping them informed about the
      latest open house listings.{' '}
      <BtnAnch
       className="colored-link-1"
       onClick={() => setModal({ show: true, mode: 'ADD' })}
      >
       Click here to set up a ‘Open House Alert’
      </BtnAnch>
     </p>
     //  </div>
    )
   case 'price-drop':
    return (
     //  <div className="table-empty-content">
     <p>
      The "Price Drop Alert" feature allows you to set up listing alerts based
      on City, Property type, Price range, “%” of Price Drop, Bed, Bath etc. You
      can choose the alert frequency and assign them to Tags, Stages, or
      Individual leads. Once set, email alerts will be sent to your leads
      whenever a matching property is listed, keeping them informed about the
      latest price dropped listings.{' '}
      <BtnAnch
       className="colored-link-1"
       onClick={() => setModal({ show: true, mode: 'ADD' })}
      >
       Click here to set up a ‘Price Drop Alert’
      </BtnAnch>
     </p>
     //  </div>
    )
   default:
    return undefined
  }
 }, [type])

 return (
  <>
   <ListContentLayout
    title={alertTitle}
    actions={
     <AlertBulkActionBlock
      selectedKeys={selectedKeys}
      onClickAdd={() => setModal({ show: true, mode: 'ADD' })}
      alertRefresh={refetch}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey, fead: type })}
      type={type}
     />
    }
   >
    {!isLoading &&
    (searchKey?.length ?? 0) === 0 &&
    (!flatData || (flatData && flatData.length === 0)) ? (
     <Row gutter={15} className="table-empty-content">
      <Col
       span={16}
       className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
      >
       {AlertEmptyMessage}
      </Col>
     </Row>
    ) : (
     <MDataTable
      totalData={meta?.total || 1000}
      search={{
       placeholder: 'Enter Alert Name',
       onSearch: setSearchKey,
       show: true,
      }}
      onSelectRow={onSelectRow}
      rowSelectionState={{
       ...rowSelectionState,
      }}
      loading={isLoading}
      isFetching={isRefetching}
      hasMore={hasMore}
      fetchMore={() => {
       fetchMore()
      }}
      data={flatData}
      columns={AlertTableColumns({
       handleClickEdit: (val) =>
        setModal({ show: true, mode: 'EDIT', data: val }),
      })}
      // emptyNode={AlertEmptyMessage}
     />
    )}
   </ListContentLayout>
   {modalState.show && (
    <Modal
     footer={null}
     width={1100}
     title={`${modalState.mode === 'EDIT' ? 'Edit' : 'Add '} ${alertTitle}`}
     onCancel={() => {
      setModal({ show: false })
      form.resetFields()
     }}
     visible={modalState.show}
    >
     {/* {modalState.show && ( */}
     <AlertFormSection
      show={modalState.show}
      form={form}
      type={type}
      mode={modalState.mode}
      editData={modalState.data as any}
      onCancel={() => {
       setModal({ show: false })
       form.resetFields()
      }}
      refresh={refetch}
     />
    </Modal>
   )}
  </>
 )
}

export default AlertList
