/* eslint-disable no-nested-ternary */
import { FC, ReactNode, useEffect, useState } from 'react'
import ListContentLayout from 'layout/ListContentLayout'
import ActionBlockRealtorDetail from 'features/realtors/components/ActionBlockRealtorDetail'
import RealtorDetailTail from 'features/realtors/components/RealtorDetailTail'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import { Dropdown, Menu, Checkbox, Row, Col } from 'antd'
import { useGlobal } from 'raducer/global'
import useAuth from 'hooks/useAuth'
import { useWebSettings } from 'raducer/webSettings'
import { BtnAnch } from 'components/elements/global'
import LeadOverlaySection from './LeadOverlaySection'
import { useLeadFilter } from '../slice/leadSlice'
import { TGetLeads, TLeadCustomSelectAll, TLeadTabType } from '../types'
import { useGetLoadFiltersQuery } from '../api'
import BuilkActionBlock from './BuilkActionBlock'
import LeadTileBlock from './LeadTileBlock'
import {
 convertLeadFilters,
 getLeadListSelectAllDropDown,
 handleChangeLeadFilter,
 transformLeadFilterKeys,
} from '../functions'
import AddMoreColumnBtn from './AddMoreColumnBtn'
import LeadListDynamicColumns from './LeadListColumns'
import LeadTableColumns from './tableColums'

type TLeadList = {
 leads?: TGetLeads[]
 isLoading: boolean
 isRefetching?: boolean
 refetch: () => void
 fetchMore: () => void
 hasMore?: boolean
 totalCount?: number
 title: ReactNode
 from: 'lead' | 'transfer-lead'
 realtorId?: string
 relatorName?: string
 selectedLeadsType?: 'all-leads' | 'my-leads'
}

const LeadList: FC<TLeadList> = ({
 leads,
 isLoading,
 refetch,
 fetchMore,
 hasMore,
 totalCount,
 title,
 from,
 realtorId,
 relatorName,
 selectedLeadsType,
 isRefetching,
}) => {
 const {
  filter,
  filterParams,
  searchKey,
  updateLeadFilter,
  updateSearchKey,
  clearLeadFilter,
  tileKey: leadTab,
 } = useLeadFilter()
 const [leadIndex, setLeadIndex] = useState<number | null>(null)
 const [tileKey, setTileKey] = useState<TLeadTabType>('totallead')
 const { user } = useAuth()

 useEffect(() => {
  if (leadTab) {
   setTileKey(leadTab)
  }
 }, [leadTab])

 //  const { global } = useGlobal()
 //  const { table_structure } = global || {}
 const { table_structure } = useWebSettings()
 const { data: filterKeys } = useGetLoadFiltersQuery(null, {
  ...(from === 'lead' ? { skip: !leads?.length } : {}),
 })

 const {
  onSelectRow,
  rowSelectionState,
  selectAll,
  unSelected,
  setSelectAll,
  setUnSelected,
  setSelectedRow,
  selectedRows,
  selectAllType,
  setSelectAllType,
 } = useSelectAll(filterParams)

 const clearSelectAll = () => {
  setSelectAll(false)
  setSelectedRow([])
  setUnSelected([])
  setSelectAllType(null)
 }

 useEffect(() => {
  if (from === 'lead') {
   if (selectAllType !== null && leads && leads?.length > 0) {
    const newunSelected = new Set(unSelected)
    const result = leads.filter((obj: any, index) => {
     if (selectAllType === 'all') {
      return !newunSelected.has(obj.id)
     }
     if (['50', '100', '200', '400', '500', '1000']?.includes(selectAllType)) {
      return !newunSelected.has(obj.id) && index < Number(selectAllType)
     }
     return []
    })
    setSelectedRow(result)
   }
  } else if (from === 'transfer-lead') {
   if (selectAll && leads && leads?.length > 0) {
    const newunSelected = new Set(unSelected)
    const result = leads.filter((obj: any) => !newunSelected.has(obj.id))
    setSelectedRow(result as any)
   }
  }
 }, [leads, selectAllType, unSelected, selectAll])

 const commonActionBlockProps = {
  clearAllKeys: clearSelectAll,
  selectAll,
  unSelected,
  filterInfo: JSON.stringify(filterParams),
  selectedRows,
  totalCount,
  selectAllType,
  onRemoveSelectedKey: (id: any) => {
   setSelectedRow((prv) => {
    let oldArray = prv
    oldArray = oldArray?.filter((v) => v.id !== id)
    return [...(oldArray as any)]
   })
   if (selectAll) {
    setUnSelected((prv) => [...prv, id])
   }
  },
 }

 const onSelectDropDown = (v: TLeadCustomSelectAll) => {
  if (from === 'lead') {
   setUnSelected([])
   setSelectAllType(v)
   if (v === null) {
    setSelectedRow([])
   }
  }
 }

 const tableColumnProps: any = {
  onClickLead: (index: any) => setLeadIndex(index),
  tileKey,
  onSubmitCustomDate: (val: any) => {
   updateLeadFilter?.({
    created: val?.value,
    ...(val?.value === 'Custom'
     ? {
        from_date: val?.range?.from_date || '',
        to_date: val?.range?.to_date || '',
       }
     : {}),
   })
  },
  selectedLeadsType,
  filter: filterKeys,
  activeFilter: filter,
  account_type: user?.account_type,
  from,
 }

 const [externalModalTrigger, setExternalModalTrigger] = useState<any>(false)

 const emptyContent = (
  <Row gutter={15} className="table-empty-content">
   <Col
    span={16}
    className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
   >
    <p>
     The &quot;Leads&quot; page lists all leads added to the CRM through various
     methods. You can search and filter leads by tag, stage, source & created
     date, and view their activity. Select leads to assign tags, stages, alerts,
     and action plans.{' '}
     <BtnAnch
      className="colored-link-1"
      onClick={() => {
       setExternalModalTrigger('import')
      }}
     >
      Click here to import leads in bulk
     </BtnAnch>{' '}
     or{' '}
     <BtnAnch
      className="colored-link-1"
      onClick={() => {
       setExternalModalTrigger('lead')
      }}
     >
      click here to add a new lead
     </BtnAnch>{' '}
     .
    </p>
   </Col>
  </Row>
 )

 return (
  <>
   <LeadOverlaySection
    leadIndex={leadIndex}
    setLeadIndex={setLeadIndex}
    fetchMore={fetchMore}
    hasMore={hasMore}
    leads={leads}
    isRealtor={from === 'transfer-lead'}
    refetchList={refetch}
    realtorId={realtorId as any}
   />
   <ListContentLayout
    bodyClass={from === 'lead' ? 'lead-list-body' : 'relator-body-height'}
    title={title}
    actions={
     from === 'lead' ? (
      <BuilkActionBlock
       hasMore={hasMore}
       leadRefresh={refetch}
       selectedLeadsType={selectedLeadsType}
       leads={leads}
       {...commonActionBlockProps}
       externalModalTrigger={externalModalTrigger}
       setExternalModalTrigger={setExternalModalTrigger}
      />
     ) : (
      <ActionBlockRealtorDetail
       realtorId={realtorId}
       onSuccess={refetch}
       hasMore={hasMore}
       {...commonActionBlockProps}
      />
     )
    }
   >
    {from !== 'lead' && (
     <RealtorDetailTail name={relatorName} id={`${realtorId}`} />
    )}
    {!isLoading &&
    from === 'lead' &&
    Object.keys(filter ?? {})?.length === 0 &&
    (searchKey?.length ?? 0) === 0 &&
    (!leads || (leads && leads.length === 0)) ? (
     emptyContent
    ) : (
     <MDataTable
      //   currentFilter={convertLeadFilters(filter)}

      currentFilter={
       from === 'lead' &&
       (selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor')
        ? (transformLeadFilterKeys(
           //  global?.table_structure ?? [],
           table_structure ?? [],
           convertLeadFilters(filter) as Record<string, string>,
           'toFilter',
          ) as any)
        : convertLeadFilters(filter)
      }
      excludeResetButtonValues={[
       { id: 'email_address', value: 'All' },
       { id: 'phone_number', value: 'All' },
       { id: 'created_at', value: 'All' },
      ]}
      extraHeaderDom={
       from === 'lead' && (
        <LeadTileBlock
         selectedLeadsType={selectedLeadsType}
         onChangeTile={clearSelectAll}
         selectedKey={tileKey}
         setKey={setTileKey}
        />
       )
      }
      onReset={() => {
       setTileKey('totallead')
       clearLeadFilter()
      }}
      onChangeFilter={(v) => {
       if (v) {
        if (
         from === 'lead' &&
         (selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor')
        ) {
         updateLeadFilter?.(
          handleChangeLeadFilter(
           transformLeadFilterKeys(
            // global?.table_structure ?? [],
            table_structure ?? [],
            v as Record<string, string>,
            'fromFilter',
           ) as any,
          ),
         )
        } else {
         updateLeadFilter?.(handleChangeLeadFilter(v))
        }
       }
      }}
      totalData={totalCount}
      search={{
       placeholder: 'Name | Phone | Email',
       onSearch: updateSearchKey,
       show: true,
      }}
      onSelectRow={(data: any, checked?: boolean): void => {
       if (from === 'transfer-lead') {
        onSelectRow(data, checked)
       } else if (from === 'lead') {
        if (selectAllType !== null) {
         setUnSelected((prv) => {
          let array = prv ?? []
          if (checked === false) {
           array = [...array, data?.id]
          } else {
           array = prv.filter((m) => m !== data?.id)
          }
          return [...array]
         })
        } else {
         setUnSelected([])
        }
       }
      }}
      rowSelectionState={{
       ...rowSelectionState,
       ...(from === 'lead' ? { dropDownValue: selectAllType } : {}),
       onSelectedAll:
        from === 'transfer-lead'
         ? (b) => {
            setUnSelected([])
            setSelectAll?.(b)
            if (b === false) {
             setSelectedRow([])
            }
           }
         : undefined,
      }}
      loading={isLoading}
      isFetching={isRefetching}
      hasMore={hasMore}
      fetchMore={() => {
       fetchMore()
      }}
      data={leads}
      columns={
       from === 'lead' &&
       (selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor')
        ? LeadListDynamicColumns({
           ...tableColumnProps,
           table_structure,
          })
        : LeadTableColumns({
           ...tableColumnProps,
          })
      }
      selectAllCustomNode={
       <div className="" style={{ marginLeft: '6px' }}>
        <Checkbox
         onChange={({ target }) => {
          if (from === 'lead') {
           onSelectDropDown(target?.checked ? 'all' : null)
          } else {
           setUnSelected([])
           setSelectAll(target?.checked)
           if (target?.checked === false) {
            setSelectedRow([])
           }
          }
         }}
         checked={from === 'lead' ? selectAllType === 'all' : selectAll}
         indeterminate={selectAllType !== null && selectAllType !== 'all'}
        />
        {from === 'lead' && (
         <Dropdown
          overlay={
           <Menu
            items={getLeadListSelectAllDropDown({
             clearSelectAll,
             selectedRows,
             totalCount: totalCount ?? 0,
             onSelectDropDown,
            })}
           />
          }
         >
          <i className="fas fa-chevron-down ps-1 table-select-all-arrow" />
         </Dropdown>
        )}
       </div>
      }
      isDraggableColumn={
       from === 'lead' &&
       (selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor')
      }
      //  emptyNode={
      //   <p>
      //    The &quot;Leads&quot; page lists all leads added to the CRM through
      //    various methods. You can search and filter leads by tag, stage, source &
      //    created date, and view their activity. Select leads to assign tags,
      //    stages, alerts, and action plans.{' '}
      //    <BtnAnch
      //     className="colored-link-1"
      //     onClick={() => {
      //      setExternalModalTrigger('import')
      //     }}
      //    >
      //     Click here to import leads in bulk
      //    </BtnAnch>{' '}
      //    or{' '}
      //    <BtnAnch
      //     className="colored-link-1"
      //     onClick={() => {
      //      setExternalModalTrigger('lead')
      //     }}
      //    >
      //     click here to add a new lead
      //    </BtnAnch>{' '}
      //    .
      //   </p>
      //  }
     />
    )}
    {/* {from === 'lead' &&
     (selectedLeadsType === 'my-leads' || user?.account_type === 'Realtor') && (
      <AddMoreColumnBtn refetch={refetch} />
     )} */}
   </ListContentLayout>
  </>
 )
}

export default LeadList
