/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Row } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { ADMIN_PREFIX_PATH } from 'config'
import { useWebSettings } from 'raducer/webSettings'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { baseAPI, useManageWebsiteMutation } from 'api/baseApi'
import useAuth from 'hooks/useAuth'
import { useAppDispatch } from 'hooks'
import { useUpdateOnboardMutation } from '../api'
import UserOnBoardVideoModal from '../components/UserOnBoardVideoModal'
import { UserOnboardSetupItems } from '../components/UserOnboardSetupItems'

const UserOnboard: FC = () => {
 const { onboarding_settings } = useWebSettings()
 const [updateOnboard] = useUpdateOnboardMutation()
 const [manageWebsiteMutation] = useManageWebsiteMutation()
 const [showVidModal, setShowVidModal] = useState(false)
 const navigate = useNavigate()
 const d = useAppDispatch()
 const { onBoardListingStatus } = onboarding_settings || {}

 const { user } = useAuth()
 const { updateUserOnboardGlobalState, updateQrModalData } = useWebSettings()
 const handleClick = (v: {
  to?: string
  action?: 'manage-website' | 'download-app'
 }): void => {
  const { to, action } = v || {}
  updateOnboard({ type: 'first_open' })

  if (!action) {
   navigate(`${ADMIN_PREFIX_PATH}/${to}`)
   if (to === 'leads/all-leads') {
    updateUserOnboardGlobalState({ showLeadImportPopup: true })
   }
  } else {
   navigate(`${ADMIN_PREFIX_PATH}/dashboard`)
   if (action === 'manage-website') {
    manageWebsiteMutation?.().then((res: any) => {
     if (!onBoardListingStatus?.manage_website) {
      d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
     }
     if (res?.data?.url) {
      window.open(res.data.url, '_blank')
     }
    })
   } else if (action === 'download-app') {
    updateQrModalData({
     type: 'show',
     data: { hideQrPopup: 'show-over-onboard', minimizeOnBoardPopup: true },
    })
   }
  }
 }

 const [detailsContent, setDetailsContent] = useState({
  heading: 'Update your profile',
  desc: 'Complete your profile to personalize your CRM experience',
  button: 'Update Profile',
  icon: <i className="fas fa-user-plus" />,
  isCompleted: false,
  onClickparam: { to: 'my-profile' },
 })

 const [isVisible, setIsVisible] = useState(true)

 const handleSetValue = (value: any): void => {
  setIsVisible(false)

  setTimeout(() => {
   setDetailsContent(value)
   setIsVisible(true)
  }, 200)
 }

 const onCloseUserOnboard = async (): Promise<void> => {
  navigate(`${ADMIN_PREFIX_PATH}/dashboard`)

  await updateOnboard({ type: 'first_open' })
 }

 return (
  <div className="u-onboard">
   <div className="u-onboard-close">
    <div className="close" onClick={onCloseUserOnboard}>
     <i className="fal fa-times" />
    </div>
   </div>
   <div className="content">
    <Row gutter={[34, 34]} className="content-row">
     <div className="left-wrapper">
      <div className="left">
       <div className="left-content">
        <h2 className="left-title">Hello!</h2>
        <h1 className="left-name">{user?.name}</h1>
        <p className="left-desc">
         We’re happy to bring you aboard Canada’s favorite RealEstate CRM!
        </p>
       </div>
       <div className="left-video">
        <div className="video-wrap">
         <div>
          <h4 className="video-title">Let’s Get Started</h4>
          <h5 className="video-subtitle">Watch a one-minute video</h5>
          <p className="video-desc">View the key features we offer</p>
         </div>
         <div onClick={() => setShowVidModal(true)}>
          <img
           alt=""
           src="/images/user-onboard-frame.png"
           className="video-img"
          />
         </div>
        </div>
       </div>
      </div>
      {/* </div> */}
     </div>
     <div className="right-wrapper">
      {/* <div className="right-wrapper"> */}
      <div className="right">
       <div className="setup">
        <h1 className="head">SET UP YOUR CRM</h1>
        <p className="desc">Make your CRM smarter and more interactive</p>
        <UserOnboardSetupItems
         mode="page"
         onHover={setDetailsContent}
         selectedValue={detailsContent?.heading}
        />
       </div>
       <div
        className="details"
        //    style={{opacity:isVisible? '100':'0'}}
       >
        <div className="icon">{detailsContent?.icon}</div>
        {/* <i className="fas fa-layer-group icon" /> */}
        <h2 className="head">{detailsContent?.heading}</h2>
        <p className="desc">{detailsContent?.desc}</p>
        <BaseButton
         className="w-100 "
         size="large"
         type="primary"
         onClick={() => handleClick(detailsContent?.onClickparam)}
        >
         {detailsContent?.button}
        </BaseButton>
       </div>
      </div>
     </div>
    </Row>
   </div>
   {showVidModal && (
    <UserOnBoardVideoModal onCancel={() => setShowVidModal(false)} />
   )}
  </div>
 )
}

export default UserOnboard
