/* eslint-disable no-nested-ternary */
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Avatar, Card, Col, Modal, Row, Skeleton } from 'antd'
import BaseEmptyBlock from 'components/elements/BaseEmpty/BaseEmptyBlock'
import { BtnAnch, globalConfirm } from 'components/elements/global'
import {
 useIntegrationQuery,
 useUnlinkFbPageMutation,
} from 'features/integration/api'
import IntegrationCard from 'features/integration/components/IntegrationCard'
import { TIntegrationItem } from 'features/integration/types'
import ListContentLayout from 'layout/ListContentLayout'
import { LoginButton, useFacebook, useLogin } from 'react-facebook'
import { useAppDispatch } from 'hooks'
import { useWebSettings } from 'raducer/webSettings'
import { baseAPI } from 'api/baseApi'

const Integrations: FC = () => {
 const { Meta } = Card
 //  const d = useAppDispatch()
 //  const { onboarding_settings } = useWebSettings()
 //  const { onBoardListingStatus } = onboarding_settings || {}

 const { data: integrationData, isLoading } = useIntegrationQuery()
 const [unLinkfbPage] = useUnlinkFbPageMutation()

 const [intItems, setIntItems] = useState(integrationData ?? [])
 useEffect(() => {
  setIntItems(integrationData ?? [])
 }, [integrationData])

 const handleUnlinkFbPage = async (page_id?: any): Promise<void> => {
  globalConfirm({
   cancelText: 'No',
   okText: 'Yes',
   content: 'Are you sure want to remove this page?',
   onOk: async () => {
    await unLinkfbPage?.({ page_id })
    setIntItems((prv) => prv.filter((item) => item.page_id !== page_id))
   },
  })
 }

 const { login } = useLogin()

 const refetch = async () => {
  Modal?.destroyAll()
  const response = await login({
   scope:
    'pages_read_engagement,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,ads_management,business_management',
  })
  handleSuccess(response, false)
 }

 const { init } = useFacebook()

 const containsItem = (item: any) => {
  for (let i = 0; i < intItems?.length; i++) {
   if (intItems?.[i].page_id === `${item.id}`) {
    return true
   }
  }
  return false
 }

 const handleSuccess = async (res?: any, firstTry?: boolean) => {
  const api = await init()
  const FB = await api?.getFB()
  console.log(res, 'fb res')
  if (res?.status === 'connected') {
   FB.api('/me/accounts', (accRes: any) => {
    if ((accRes?.data?.length === 0 || !accRes?.data) && firstTry) {
     Modal.confirm({
      className: 'mts-confirm',
      title: 'Something Went Wrong!',
      icon: (
       <div className="con-icon-block">
        <i className="far fa-exclamation-triangle" />
       </div>
      ),
      okButtonProps: {
       style: { display: 'none' },
      },
      content: (
       <p>
        Connection request failed! Please{' '}
        <BtnAnch className="colored-link-1" onClick={() => refetch()}>
         Click Here
        </BtnAnch>{' '}
        to resend the request.
       </p>
      ),
     })
    } else {
     //  if (!onBoardListingStatus?.facebook_integration) {
     //   d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
     //  }

     for (let j = 0; j < accRes?.data?.length; j++) {
      if (!containsItem(accRes?.data?.[j])) {
       const { access_token, id, name } = accRes?.data?.[j] || {}
       setIntItems((prv) => [
        ...prv,
        {
         page_access_token: access_token,
         page_id: id,
         page_name: name,
         status: 'Activate',
        },
       ])
      }
     }
    }
   })
  }
 }

 return (
  <div className="integration">
   <ListContentLayout
    title="Integration"
    actions={<LoginBtn intItems={intItems} setIntItems={setIntItems} />}
   >
    {isLoading ? (
     <Row>
      {[...Array(4)].map((_, i) => (
       <Col key={i} sm={12} lg={8} className="p-2">
        <Card>
         <Skeleton loading avatar active>
          <Meta avatar={<Avatar />} />
         </Skeleton>
        </Card>
       </Col>
      ))}
     </Row>
    ) : intItems?.length === 0 ? (
     //  <BaseEmptyBlock description="No pages linked" />
     <Row gutter={15} className="table-empty-content">
      <Col
       span={16}
       className="tex-center d-flex flex-column align-items-center justify-content-center my-auto mx-auto"
      >
       <p>
        The &quot;Integrations&quot; page allows you to connect third-party
        applications to AgentRoof CRM. Currently, AgentRoof supports Facebook
        Lead Ads Integration.&nbsp;
        <BtnAnch className="colored-link-1 integration-btn">
         <LoginButton
          scope="pages_read_engagement,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,ads_management"
          onError={(err) => console.log(err)}
          onSuccess={(res) => handleSuccess?.(res, true)}
          reauthorize
         >
          Click here to get started.
         </LoginButton>
        </BtnAnch>
       </p>
      </Col>
     </Row>
    ) : (
     <Row>
      {intItems?.map?.((itm, _) => (
       //  <Col key={_}>
       <IntegrationCard
        itm={itm}
        setIntItems={setIntItems}
        handleUnlinkFbPage={handleUnlinkFbPage}
        key={_}
       />
       //  </Col>
      ))}
     </Row>
    )}
   </ListContentLayout>
  </div>
 )
}

export default Integrations

export const LoginBtn: FC<{
 intItems: TIntegrationItem[]
 setIntItems: Dispatch<SetStateAction<TIntegrationItem[]>>
}> = ({ intItems, setIntItems }) => {
 const { init } = useFacebook()
 //  const { onboarding_settings } = useWebSettings()
 //  const { onBoardListingStatus } = onboarding_settings || {}
 //  const d = useAppDispatch()

 const containsItem = (item: any) => {
  for (let i = 0; i < intItems?.length; i++) {
   if (intItems?.[i].page_id === `${item.id}`) {
    return true
   }
  }
  return false
 }

 const { login } = useLogin()

 const refetch = async () => {
  Modal?.destroyAll()
  const response = await login({
   scope:
    'pages_read_engagement,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,ads_management,business_management',
  })
  handleSuccess(response, false)
 }

 const handleSuccess = async (res?: any, firstTry?: boolean) => {
  const api = await init()
  const FB = await api?.getFB()
  console.log(res, 'fb res')
  if (res?.status === 'connected') {
   FB.api('/me/accounts', (accRes: any) => {
    if ((accRes?.data?.length === 0 || !accRes?.data) && firstTry) {
     Modal.confirm({
      className: 'mts-confirm',
      title: 'Something Went Wrong!',
      icon: (
       <div className="con-icon-block">
        <i className="far fa-exclamation-triangle" />
       </div>
      ),
      okButtonProps: {
       style: { display: 'none' },
      },
      content: (
       <p>
        Connection request failed! Please{' '}
        <BtnAnch className="colored-link-1" onClick={() => refetch()}>
         Click Here
        </BtnAnch>{' '}
        to resend the request.
       </p>
      ),
     })
    } else {
     //  if (!onBoardListingStatus?.facebook_integration) {
     //   d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
     //  }

     for (let j = 0; j < accRes?.data?.length; j++) {
      if (!containsItem(accRes?.data?.[j])) {
       const { access_token, id, name } = accRes?.data?.[j] || {}
       setIntItems((prv) => [
        ...prv,
        {
         page_access_token: access_token,
         page_id: id,
         page_name: name,
         status: 'Activate',
        },
       ])
      }
     }
    }
   })
  }
 }

 return (
  <div className="fb-login-btn">
   <LoginButton
    scope="pages_read_engagement,leads_retrieval,pages_manage_metadata,pages_show_list,pages_manage_ads,ads_management"
    onError={(err) => console.log(err)}
    onSuccess={(res) => handleSuccess?.(res, true)}
    reauthorize
   >
    <i className="far fa-plus me-2" /> Add New
   </LoginButton>
  </div>
 )
}
