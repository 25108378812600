/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Skeleton, Card, Tooltip } from 'antd'
import React, { FC, ReactNode } from 'react'
import { SelectType, TColor } from 'types'
import { CountUp } from '../routes/CountUp'

type TExtras = SelectType & { onClick?: () => void }

const DashboardCard: FC<{
 title?: string
 extra?: TExtras[]
 color?: TColor
 icon?: ReactNode
 loading?: boolean
}> = ({ title, extra, color = 'primary', icon, loading }) => {
 return (
  <Card className={`dash-card dash-card-${color}`}>
   {loading ? (
    <Skeleton active paragraph={{ rows: 1 }} />
   ) : (
    <>
     <h4 className="dash-title">
      {icon} {title}
     </h4>
     <ul className="extra-dash">
      {extra?.map((v, i) => (
       <li
        key={`card-${i}`}
        onClick={v?.onClick ? () => v?.onClick?.() : undefined}
       >
        <Tooltip overlay={v?.label}>
         <h6
          style={{
           overflow: 'hidden',
           textOverflow: 'ellipsis',
          }}
         >
          {v?.label}
         </h6>
        </Tooltip>
        <p>
         {/* {v.value} */}
         <CountUp end={v.value} />{' '}
        </p>
       </li>
      ))}
     </ul>
    </>
   )}
  </Card>
 )
}

export default DashboardCard
