import { FC } from 'react'
import { Route, Routes } from 'react-router'
import UserOnboard from './UserOnboard'

export const UserOnbordRoutes: FC = () => {
 return (
  <Routes>
   <Route path="" element={<UserOnboard />} />
  </Routes>
 )
}
