import { useAppSelector, useAppDispatch } from 'hooks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { TDashboardToLeadDetail } from 'features/misc'
import { TLeadFilterBody, TLeadTabType } from '../types'
import { handleLeadFilterTab, toLeadFilterObj } from '../functions'

const filterInitState: TLeadFilterBody = {
 created: null,
 email: null,
 phone: null,
 source: null,
 stage: null,
 tag: null,
 expert: null,
 from_date: null,
 to_date: null,
}

type TRecentLeadData = Omit<TDashboardToLeadDetail, 'fetchMore' | 'refetch'>

type TInitialState = {
 filter: TLeadFilterBody
 searchKey: string
 filterParams: any
 tileKey?: TLeadTabType | null
 recentLeadData?: TRecentLeadData | null
}

const initialState: TInitialState = {
 filter: filterInitState,
 searchKey: '',
 filterParams: { search: '' },
}
export const LeadSlice = createSlice({
 name: 'leadSlice',
 initialState,
 reducers: {
  updateLeadFilter: (state, { payload }: PayloadAction<TLeadFilterBody>) => {
   state.filter = { ...state.filter, ...payload }
   state.filterParams = toLeadFilterObj(
    { ...state.filter, ...payload },
    state.searchKey,
   )
  },
  setLeadFilter: (state, { payload }: PayloadAction<TLeadFilterBody>) => {
   state.filter = { ...payload }
   state.filterParams = toLeadFilterObj({ ...payload }, '')
  },
  updateSearchKey: (state, { payload }: PayloadAction<string>) => {
   state.searchKey = payload
   state.filterParams = toLeadFilterObj(state?.filter, payload)
  },
  handleUpdateTab: (state, { payload }: PayloadAction<TLeadTabType>) => {
   state.searchKey = ''
   state.filter = handleLeadFilterTab(payload)
   state.filterParams = toLeadFilterObj(
    handleLeadFilterTab(payload),
    state?.searchKey,
   )
  },
  clearLeadFilter: (state) => {
   state.searchKey = ''
   state.filter = {}
   state.filterParams = { search: '' }
  },
  updateLeadListTile: (
   state,
   { payload }: PayloadAction<TLeadTabType | null>,
  ) => {
   state.tileKey = payload
  },
  updateRecentLeadData: (
   state,
   { payload }: PayloadAction<TRecentLeadData | null>,
  ) => {
   state.recentLeadData = payload
  },
 },
})

export const {
 updateLeadFilter,
 updateSearchKey,
 handleUpdateTab,
 clearLeadFilter,
 setLeadFilter,
 updateLeadListTile,
 updateRecentLeadData,
} = LeadSlice.actions

export const useLeadFilter = (): {
 filter: TLeadFilterBody
 searchKey: string
 filterParams: any
 tileKey?: TLeadTabType | null
 recentLeadData?: TRecentLeadData | null
 updateLeadFilter?: (v: TLeadFilterBody) => void
 updateSearchKey?: (v: string) => void
 handleUpdateTab: (v: TLeadTabType) => void
 setLeadFilter: (v: TLeadFilterBody) => void
 clearLeadFilter: () => void
 updateLeadListTile: (tileKey: TLeadTabType | null) => void
 updateRecentLeadData: (v: TRecentLeadData | null) => void
} => {
 const d = useAppDispatch()
 return {
  ...useAppSelector((state: RootState) => state.LeadSlice),
  updateLeadFilter: (v) => d(updateLeadFilter(v)),
  updateSearchKey: (v) => d(updateSearchKey(v)),
  handleUpdateTab: (v) => d(handleUpdateTab(v)),
  setLeadFilter: (v) => d(setLeadFilter(v)),
  clearLeadFilter: () => d(clearLeadFilter()),
  updateLeadListTile: (v) => d(updateLeadListTile(v)),
  updateRecentLeadData: (v) => d(updateRecentLeadData(v)),
 }
}
