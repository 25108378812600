import { baseTime } from 'lib'
import { useWebSettings } from 'raducer/webSettings'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const USER_ONBOARD_LOCAL = 'user-onboard-local'

const useUpdateQrPopupEvery24H = (): void => {
 const { updateQrModalData, app_user } = useWebSettings()
 const storedData = localStorage.getItem(USER_ONBOARD_LOCAL)
  ? JSON.parse(localStorage.getItem(USER_ONBOARD_LOCAL) ?? '')
  : null

 useEffect(() => {
  if (
   storedData?.hideQrPopup &&
   storedData?.hideQrPopup !== 'show' &&
   storedData?.hideQrPopup !== 'show-over-onboard'
  ) {
   if (baseTime().isAfter(baseTime(storedData?.hideQrPopup).add(1, 'day'))) {
    updateQrModalData({
     ...(app_user
      ? {
         type: 'show',
         data: {
          hideQrPopup: 'show',
          minimizeOnBoardPopup: true,
         },
        }
      : { type: 'remove-app-data', data: {} }),
    })
   }
  }
 }, [storedData])
}

export default useUpdateQrPopupEvery24H
