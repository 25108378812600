// import { SidebarLinksType } from 'configs/SidebarLinks';
import React, { FC, useMemo } from 'react'
import { Menu, Layout } from 'antd'
import { useLocation, useNavigate } from 'react-router'
import { SidebarLinksType } from 'static-data/SidebarLinks'
import { Link } from 'react-router-dom'
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from 'config'
import { baseAPI, useManageWebsiteMutation } from 'api/baseApi'
import { useWebSettings } from 'raducer/webSettings'
import { useAppDispatch } from 'hooks'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import style from './Sidebar.module.scss'

type SidebarProps = {
 sidebarLInk: SidebarLinksType[]
}

const Sidebar: FC<SidebarProps> = ({ sidebarLInk }) => {
 const { pathname } = useLocation()
 const navigate = useNavigate()
 const d = useAppDispatch()
 const { onboarding_settings } = useWebSettings()
 const { onBoardListingStatus } = onboarding_settings || {}
 const { updateRecentLeadData, recentLeadData } = useLeadFilter()
 const [manageWebsiteMutation] = useManageWebsiteMutation()
 //  const selectedKeys = useMemo(() => {
 //   if (pathname.includes('admin')) {
 //    return pathname.split('admin/')?.[1] ?? ''
 //   }
 //   return ''
 //  }, [pathname])

 const manageWebsite = () => {
  manageWebsiteMutation?.()
   .then((res: any) => {
    if (!onBoardListingStatus?.manage_website) {
     d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
    }
    if (res?.data?.url) {
     window.open(res.data.url, '_blank')
    }
   })
   .catch((err) => {
    console.log(err)
   })
 }

 return (
  <Layout.Sider theme="light" className={style.crmSidebar} collapsed>
   <Link
    onClick={() => {
     if (recentLeadData) {
      updateRecentLeadData?.(null)
     }
    }}
    to={`${ADMIN_PREFIX_PATH}/dashboard`}
   >
    <div className={style.logo}>
     <img src="/logo-sm.png" alt="" />
    </div>
   </Link>

   <Menu
    selectable
    selectedKeys={pathname ? [pathname] : []}
    onClick={(e) => {
     if (e?.domEvent?.ctrlKey && e?.key !== 'manage-website') {
      window.open(e?.key, '_blank')
     } else if (e?.key === 'manage-website') {
      manageWebsite()
     } else if (e?.key === `${APP_PREFIX_PATH}/dashboard` && recentLeadData) {
      updateRecentLeadData?.(null)
     } else {
      navigate(e?.key)
     }
    }}
    theme="light"
    // defaultSelectedKeys={['1']}
    items={sidebarLInk}
    mode="vertical"
    className={style.sidebarItems}
   />
  </Layout.Sider>
 )
}

export default Sidebar
