/* eslint-disable no-nested-ternary */
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { BtnAnch } from 'components/elements/global'
import { baseTime } from 'lib'
import { Tooltip, Popover } from 'antd'
import { TSSBlockList } from 'components/shared/Common'
import { TGlobalTableStructure } from 'types'
import { TGetLeads } from 'features/leads'
import { ReactNode } from 'react'

interface TLeadListColumnProps {
 onClickLead?: (id?: any) => void
 table_structure?: TGlobalTableStructure[]
}

interface THanldeRender extends TLeadListColumnProps {
 key: string
 accessKey: 'name'
 item: TGlobalTableStructure
}

const getColumn = (v: THanldeRender): MDataTableColumns<TGetLeads> => {
 const getValue = (obj: TGetLeads, key: any): string =>
  key.split('.').reduce((o: any, k: any) => (o || {})[k], obj)
 const getNestedValue = (obj: TGetLeads, path: any): string => {
  const result = path.split('.').reduce((acc: any, part: any) => {
   if (Array.isArray(acc)) {
    return acc.map((item) => item[part])
   }
   return acc && acc[part]
  }, obj)
  // console.log({ result })
  return Array.isArray(result) ? result.flat().join(', ') : result
 }

 const showArrayValues = (
  obj: TGetLeads,
  path: any,
  columnTitle?: string,
 ): ReactNode => {
  const result = path.split('.').reduce((acc: any, part: any) => {
   if (Array.isArray(acc)) {
    return acc.map((item) => item[part])
   }
   return acc && acc[part]
  }, obj)

  if (Array.isArray(result)) {
   if (result.length > 1) {
    return (
     <>
      <div className="d-flex">
       <span style={{ width: 'max-content' }} className="line-clamp-1">
        <Tooltip overlay={result?.[0]}>{result?.[0]}</Tooltip>
       </span>
       <span>
        <Popover
         overlayStyle={{ width: '300px' }}
         title={columnTitle ?? ''}
         trigger="click"
         content={
          <div>
           {/* {result?.map((i, n) => ( */}

           {result?.slice(1)?.map((i, n) => (
            <p key={n} className="mb-1">
             {n + 2}. {i} <br />
            </p>
           ))}
          </div>
         }
        >
         <span
          style={{
           border: '1px solid #ddd',
           marginLeft: '5px',
           padding: '0 4px',
           borderRadius: '3px',
           cursor: 'pointer',
           //  color: '#ED1C24',
          }}
         >
          +{result.length - 1}
         </span>
        </Popover>
       </span>
      </div>{' '}
     </>
    )
   }
   return (
    <Tooltip overlay={result?.[0]}>
     <span className="line-clamp-1">{result?.[0]}</span>
    </Tooltip>
   )
  }

  return result
  // return Array.isArray(result) ? result.flat().join(', ') : result
 }

 const {
  accessKey,
  key,
  onClickLead,
  item: { isArray, column: columnName },
 } = v || {}

 switch (key) {
  case 'name':
   return {
    render: (_, rec, i) => (
     <BtnAnch
      onClick={() => onClickLead?.(i)}
      //  onClick={() => navigate(`#lead-details/${r.lead.id}/profile`)}
     >
      <Tooltip
       placement="topLeft"
       className="dashboard-table-items"
       title={rec?.[accessKey]}
      >
       {rec?.[accessKey]}
      </Tooltip>
     </BtnAnch>
    ),
    size: 250,
    fixed: 'left',
   }
  // case 'email_log':
  // case 'lead_note':
  // case 'text_log':
  // case 'latest_new_listing_alert':
  // case 'latest_neighbourhood_alert':
  // case 'latest_open_house_alert':
  // case 'latest_price_drop_alert':
  // case 'task':
  // case 'appointment':
  // case 'plan':
  // case 'background':
  // case 'city':
  // case 'activity':
  // case 'last_activity':
  //  return {
  //   size: 200,
  //   ...(isArray ? {} : { ellipsis: true }),
  //   render: (_, rec: any) => {
  //    return isArray
  //     ? showArrayValues(rec, accessKey, columnName)
  //     : getValue(rec, accessKey)
  //   },
  //  }
  case 'phone_number':
  case 'email_address':
   return {
    render: (_, rec) => (
     <div
      className={
       key === 'phone_number'
        ? rec?.phone_number?.label === 'Bad'
          ? 'bad-label'
          : ''
        : rec?.email_address?.label === 'Bad'
        ? 'bad-label'
        : ''
      }
     >
      {key === 'phone_number'
       ? getValue(rec, accessKey)
         ? `+1 ${getValue(rec, accessKey)}`
         : ''
       : getValue(rec, accessKey)}
     </div>
    ),
    size: 200,
    ellipsis: true,
   }
  case 'created_at':
   return {
    size: 200,
    render: (val) => baseTime(val).format('DD MMM YYYY'),
   }
  case 'latest_recent_activity':
  case 'last_activity_date':
   return {
    size: 200,
    render: (val) => baseTime(val).format('DD-MM-YYYY hh:mm A'),
   }
  case 'tags':
  case 'stages':
   return {
    size: 250,
    render: (_, r) => (
     <TSSBlockList
      closable={key === 'tags'}
      type="lead"
      mode={key === 'tags' ? 'tag' : 'stage'}
      typeId={r.id ?? ''}
      data={key === 'tags' ? r.tags : r?.stages ? [r?.stages] : []}
      tooltiOptions={{
       getPopupContainer: (e) =>
        e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
         ?.parentNode as any,
      }}
     />
    ),
   }

  case 'sources':
   return {
    size: 200,
    ellipsis: false,

    render: (_, r) => {
     if (r.sources) {
      return (
       <TSSBlockList
        closable={false}
        type="lead"
        mode="source"
        typeId={r.id ?? ''}
        data={[{ name: r?.sources?.name }]}
        tooltiOptions={{
         getPopupContainer: (e) =>
          e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
           ?.parentNode as any,
        }}
       />
      )
     }
    },
   }
  case 'transferred_on':
   return {
    size: 130,
    ellipsis: true,
    render: (_, r) => (
     <div>
      {r?.transferowner
       ? baseTime(r?.transaction?.created_at).format('DD MMM YYYY')
       : ''}
     </div>
    ),
   }

  case 'instagram':
  case 'facebook':
  case 'linkedin':
  case 'whatsapp_no':
  case 'twitter':
   return {
    size: 200,
    render: (_, rec: any) => {
     const val = isArray
      ? getNestedValue(rec, accessKey)
      : getValue(rec, accessKey)
     return val ? (
      <BtnAnch
       className="line-clamp-1"
       onClick={() => window.open(val, '_blank')}
       style={{ wordWrap: 'break-word' }}
      >
       {val}
      </BtnAnch>
     ) : (
      ''
     )
    },
   }
  default:
   return {
    size: 200,
    ...(isArray ? {} : { ellipsis: true }),
    render: (_, rec: any) => {
     return isArray
      ? showArrayValues(rec, accessKey, columnName)
      : getValue(rec, accessKey)
    },
   }
 }
}
const RecentLeadColumns = ({
 onClickLead,
 table_structure,
}: TLeadListColumnProps): MDataTableColumns<TGetLeads>[] => {
 return (
  table_structure?.map((item) => ({
   title: item?.column,
   dataIndex: item?.variableCustom,
   ...getColumn({
    key: item?.variable,
    accessKey: item?.variableCustom as any,
    onClickLead,
    item,
   }),
  })) ?? []
 )
}

export default RecentLeadColumns
