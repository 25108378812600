/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox, Modal } from 'antd'
import {
 SVGDownloadFromAppStore,
 SVGDownloadFromGPlay,
 SVGScanIcon,
} from 'components/icons'
import { FC, useState } from 'react'
import { useWebSettings } from 'raducer/webSettings'
import { baseTime } from 'lib'
import { BtnAnch } from 'components/elements/global'
import { useAppDispatch } from 'hooks'
import { baseAPI } from 'api/baseApi'
import { useUpdateOnboardMutation } from '../api'

const AppDownloadModal: FC = () => {
 const [updateOnboard] = useUpdateOnboardMutation()
 const { updateQrModalData, app_user } = useWebSettings()
 const [donotShow, setDonotShow] = useState(false)
 const d = useAppDispatch()

 const onClose = async (type: 'close' | 'do-not-show'): Promise<void> => {
  updateQrModalData({
   type: donotShow ? 'do-not-show' : 'close',
   data: { hideQrPopup: donotShow ? '' : baseTime().format() },
  })
  if (donotShow) {
   await updateOnboard({ type: 'app_status' })
   if (app_user) {
    d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
   }
  }
 }

 return (
  //   <Modal visible footer={null}
  <div className="app-download-modal">
   {/* //   > */}
   <div className="content">
    <div className="scan-icon">
     <div className="scan-icon-wrapper">
      <SVGScanIcon style={{ fontSize: '28px' }} />
     </div>
    </div>
    <div className="close" onClick={() => onClose('close')}>
     <i className="far fa-times-circle" />
    </div>

    <div className="body">
     <p>Scan QR code</p>
     <img
      alt=""
      src="/images/app-qr-code.png"
      className="img-fluid w-100 img"
     />
     <div>
      <div className="or-wrapper">
       <div className="or-wrapper-lines" />
       <div className="or-text">or</div>
       <div className="or-wrapper-lines" />
      </div>
     </div>
     <div className="footer">
      <div>
       <BtnAnch
        onClick={() =>
         window.open(
          'https://play.google.com/store/apps/details?id=com.agentroof.crm&pcampaignid=web_share&pli=1',
          '_blank',
         )
        }
       >
        <SVGDownloadFromGPlay style={{ width: '98px', marginRight: '14px' }} />
       </BtnAnch>
       <BtnAnch
        onClick={() =>
         window.open(
          'https://apps.apple.com/us/app/agent-roof-crm/id1623162463',
          '_blank',
         )
        }
       >
        <SVGDownloadFromAppStore style={{ width: '98px' }} />
       </BtnAnch>
      </div>
     </div>
     <div className="d-flex justify-content-center">
      <Checkbox
       checked={donotShow}
       onChange={(e) => setDonotShow(e?.target?.checked)}
      >
       <span style={{ color: 'white' }}> Do not show again</span>
      </Checkbox>
     </div>
    </div>
   </div>
   {/* </Modal> */}
  </div>
 )
}

export default AppDownloadModal
